<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div class="uk-form-stacked">
        <h4 class="uk-heading-line">
          Role Info
        </h4>
        <div
          v-if="loadingDetail"
          class="uk-flex uk-flex-center uk-flex-middle"
        >
          <div uk-spinner />
        </div>
        <div
          v-else
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Role Name
                </label>
                <div class="uk-form-controls">
                  <input
                    name="role_name"
                    :value="detail_role.name || '-'"
                    class="uk-input readonly"
                    type="text"
                    autocomplete="off"
                    readonly
                  >
                </div>
              </div>
            </div>
          </div>
          
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Assigned at
                </label>
                <div class="uk-form-controls">
                  <input
                    name="assigned_at"
                    :value="detail_role.assigned_at || '-'"
                    class="uk-input readonly"
                    type="text"
                    autocomplete="off"
                    readonly
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Status
                </label>
                <div v-if="detail_role.status === 1">
                  <label-status
                    status="active"
                    :label="`${status.ACTIVE}`"
                    table="false"
                  />
                </div>
                <div v-else-if="detail_role.status === 0">
                  <label-status
                    status="inactive"
                    :label="`${status.INACTIVE}`"
                    table="false"
                  />
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <h4 class="uk-heading-line">
        Access Management
      </h4>
      
      <create-edit-detail-role />

      <div class="uk-flex uk-flex-right uk-margin-medium-top">
        <button
          class="uk-button red uk-margin-right"
          :class="isCanAccessUser('delete', 'ROLE') ? '' : 'disabled'"
          :disabled="!isCanAccessUser('delete', 'ROLE')"
          @click="showDeleteConfirmModal"
        >
          <img
            :src="`${images}/icon/ic_delete_red.svg`"
            alt="delete"
            loading="lazy"
            class="uk-margin-small-right"
          >
          Delete
        </button>
        <button
          class="uk-button green"
          :class="isCanAccessUser('edit', 'ROLE') ? '' : 'disabled'"
          :disabled="!isCanAccessUser('edit', 'ROLE')"
          @click.prevent="$router.push({ name: 'RoleEdit'})"
        >
          <img
            :src="`${images}/icon/ic_edit_white.svg`"
            alt="edit"
            loading="lazy"
            class="uk-margin-small-right"
          >
          Edit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { STATUS } from '@/utils/constant'
import LabelStatus from '@/components/globals/LabelStatus'
import CreateEditDetailRole from '@/components/globals/table/role/CreateEditDetail'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    LabelStatus,
    CreateEditDetailRole
  },
  props: {
    id: {
      required: true,
      type: String
    },
    images: {
      required: true,
      type: String
    },
    loadingDetail: {
      required: false,
      type: Boolean
    },
    setToggleModalAddEditDiscard: {
      required: false,
      default: null,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      status: STATUS,
      role_name: '',
      assigned_at: ''
    }
  },
  computed: {
    ...mapGetters({
      detail_role: 'role/detail_role'
    })
  },
  methods: {
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    showDeleteConfirmModal() {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        targetModals: open,
        title: 'Delete Confirmation',
        description: 'Are you sure want to delete this data?',
        customTitleBtnOk: 'Delete',
        customTitleBtnCancel: 'Cancel',
        type: 'delete',
        loadingTable: false,
        loadingPopUp: false,
        id: this.id
      })
    }
  }
}
</script>

<style scoped>
  .uk-tab > * > a{
    font-size: 14px;
    font-weight: 700;
  }
</style>
